import CardHeader from '../../containers/CardHeader'

import { Card } from 'react-bootstrap';
import DashboardTable from './DashboardTable';

const UserDashboard = () => {
    const title = "Dashboards";
    const subtitle = "Listing of active Dashboards"

    return (
        <Card>
            <CardHeader title={title} description={subtitle} />
            <DashboardTable showDetailsOnClick={false} />
        </Card>
    )
}

export default UserDashboard;
