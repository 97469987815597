
import { connect, useSelector } from 'react-redux'

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import CardHeader from '../../containers/CardHeader'

import { Breadcrumb, Card } from 'react-bootstrap'
import { FaPlus } from "react-icons/fa";
import XMLTable from './XMLTable'

const XMLList = (props) => {
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const title = "XML Feeds";
    const subtitle = "Listing of all XML Feeds"
    const headerAction = isSysadmin ? {'path': "/xml/feeds/add", 'icon':FaPlus} : {}

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/xml/">XML</Breadcrumb.Item>
          <Breadcrumb.Item active>XML Feeds</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
            <CardHeader title={title} description={subtitle} action={headerAction} />
            <XMLTable showDetailsOnClick={true} />
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(XMLList), "XML Feeds"));
