
import * as actionTypes from './actionType';

export const handleLogin = (user, token) => { return {type: actionTypes.LOGIN_USER, user, token} }
export const setToken = (token) => { return {type: actionTypes.SET_TOKEN, token} }
export const handleLogout = () => { return {type: actionTypes.LOGOUT_USER, isLoggedIn: false, user: {}, token: ""} }

export const updateUser = (firstName, lastName, email) => { return {type: actionTypes.UPDATE_USER, firstName, lastName, email} }
export const updateSettings = (settings) => { return {type: actionTypes.UPDATE_SETTINGS, settings} }


export const loginAsClient = (companyID, companyName) => { return {type: actionTypes.LOGIN_AS_CLIENT, companyID, companyName} }
export const logoutAsClient = () => { return {type: actionTypes.LOGOUT_AS_CLIENT} }