import { createStore } from '@reduxjs/toolkit';
import { loadState, saveState } from '../localStorage';
import { updateObject } from './utils';
import * as actionTypes from './actions/actionType';

import loginAPICall from '../../app/apiCalls/loginAPICall'
import { logout } from '../apiEndpoints';

const initState = {
	isLoggedIn: false,
    isAdmin: false,
    token: '',
    user: {}
}


const handleLogout = async (token) => {
    await loginAPICall(logout, {
      'token': token
    });
  }

function reducer(state = initState, action) {
    if (typeof state === 'undefined') {
	  return initState
	}
   
  const user = state.user
	switch (action.type) {
		case actionTypes.LOGIN:
			return updateObject(state, action);
    case 'LOGIN_USER':
        return {
            ...state,
            isLoggedIn: true,
            token: action.token,
            user: action.user,
            isViewingAsClient: false
        }
    case actionTypes.LOGOUT_USER:
        handleLogout(state.token)
        return action;
    case actionTypes.SET_PROJECT:
        return updateObject(state, action);
    case actionTypes.UPDATE_USER:
        user['name'] = `${action.firstName} ${action.lastName}`
        user['firstName'] = action.firstName
        user['lastName'] = action.lastName
        user['email'] = action.email
        
        return {
          ...state,
          user: user
        }
    case actionTypes.UPDATE_SETTINGS:
      console.log("update settings")
        user['settings'] = action.settings
        
        return {
          ...state,
          user: user
        }
    case actionTypes.LOGIN_AS_CLIENT:
      console.log("mock client")
      let realUser = structuredClone(user)
      user['isSysadmin'] = false
      user['isAdmin'] = true
      user['company']['id'] = action.companyID
      user['company']['company_name'] = action.companyName
      return {
        ...state,
        user: user,
        realUser: realUser,
        isViewingAsClient: true
      }
    case actionTypes.LOGOUT_AS_CLIENT:
      console.log("unmock client")
      console.table(state.realUser)
      return {
        ...state,
        user: state.realUser,
        isViewingAsClient: false
      }
    default:
      return updateObject(state, action);
  }
}

const persistedState = loadState()
const Store = createStore(reducer, persistedState);

Store.subscribe(() => {
  //console.table(Store.getState());
  saveState(Store.getState())
});

export default Store;