import CardHeader from '../../containers/CardHeader'

import { Breadcrumb, Card } from 'react-bootstrap';
import DashboardTable from './DashboardTable';

const AdminDashboard = () => {
    const title = "Client Dashboards";
    const subtitle = "Listing of active Client Dashboards"

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item active>Dashboards</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
            <CardHeader title={title} description={subtitle} />
            <DashboardTable showDetailsOnClick={false} />
        </Card>
        </>
    )
}

export default AdminDashboard;