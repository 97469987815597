import { connect, useSelector } from 'react-redux'
import { useState, useMemo } from 'react'
import { useParams, withRouter } from 'react-router-dom';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'
import ConfirmDialog from '../../containers/ConfirmDialog';

import Form from '../../containers/Form/Form'
import Input from '../../containers/Form/Input';
import FormFooter from "../../containers/Form/FormFooter";
import Select from "../../containers/Form/Select";

import bannerAPICall from '../../app/apiCalls/bannerAPICall'

import { SITE_NAME } from '../../app/globals'
import { FaEye } from "react-icons/fa";
import { banner_delete, banner_details, banner_save } from '../../app/apiEndpoints';
import InvalidPermissions from '../../containers/InvalidPermissions';
import ContentNotFound from '../../containers/ContentNotFound';
import Textarea from '../../containers/Form/Textarea';
import { Breadcrumb } from 'react-bootstrap';

const BannerForm = (props) => {
    let { siteURI, bannerID } = useParams();

    const [banner, setBanner] = useState({})
    const [inputValue, setInputValue] = useState({ company_id: 0, name: "", code: "", tracking: true });
    const [errors, setErrors] = useState({ company_id: "", name: "", code: "", tracking: "" });
    const { company_id, name, code, tracking } = inputValue;
    const [formMessage, setFormMessage] = useState('')
    const [hasErrors, setHasErrors] = useState(false)
    const [loading, setLoading] = useState(true)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const canEdit = isSysadmin
    const newBanner = !banner.banner_id

    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    const headerAction = !newBanner ? {'path': `/banners/view/${bannerID}`, 'icon':FaEye} : {}

    useMemo(() => {
        const getBanner = async () => {
            if(parseInt(bannerID) > 0) {
                const data = await bannerAPICall(banner_details, {'bannerID': bannerID, 'requester': userID, 'company': companyID })

                if(data.banner?.banner_id) {
                    const values = { name: data.banner.banner_name, code: data.banner.banner_code, tracking: data.banner.enable_tracking }
                    setInputValue(values)
                    setBanner(data.banner)
                } else if(data.error) {
                    setFormMessage(data.error)
                    setHasErrors(true)
                }
                if(data.found && data.found === "0") {
                    setBanner({ missing: true })
                }
                setLoading(false)
            }
        }
        if(parseInt(bannerID) > 0 && (banner === undefined || banner === null || !banner.banner_id) && loading) {
            getBanner()
        } else if(!bannerID || bannerID === "add") {
            setLoading(false)
        }
    }, [bannerID, userID, companyID, banner, loading])

    const saveBanner = async (e) => {
        e.preventDefault()
        let formErrors = []
        setHasErrors(false)
        for (const field of Object.entries(errors)) {
            const error = field[1]
            if(error.length > 0) formErrors.push(error)
        }
        if(formErrors.length > 0) { 
            setHasErrors(true)
            setFormMessage(formErrors.join('<br/>'))
            return
        }
        setFormMessage('')

        const data = await bannerAPICall(banner_save, {
            'siteURI': siteURI,
            'bannerID': banner.banner_id ? banner.banner_id : 0, 
            'requester': userID, 
            'company_id': company_id,
            'name': name, 
            'code': code, 
            'tracking': tracking
        })

        //if successful, delete from project object
        if(!data.success && data.error) {
            setFormMessage(data.error)
            setHasErrors(true)
            return
        } 

        if(data.banner?.banner_id) bannerID = data.banner.banner_id
        setBanner(data.banner)
        
        console.log(props)
        setFormMessage("Banner has been saved")
        window.history.replaceState(null, SITE_NAME, `/banners/${siteURI}/edit/${bannerID}`);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
          ...prev,
          [name]: value,
        }));
    };
    
    const handleError = (field, error) => {
          setErrors((prev) => ({
              ...prev,
              [field]: error,
          }));
    };

    // const confirmDeleteBanner = (e) => {
    //     e.preventDefault()
    //     setShowConfirmDelete(true)
    // }

    const deleteBanner = async () => {
        const data = await bannerAPICall(banner_delete, {'bannerID': banner.banner_id, 'requester': userID})
        console.log(data)

        //if successful, delete from project object
        if(!data.success && data.error) {
            setFormMessage(data.error)
            return
        } 

        console.log('del banner')
        setBanner({})
        
        props.history.push(`/banners/${siteURI}`)
    }

    if(bannerID === "add" && !isSysadmin)
        return <InvalidPermissions />

    if(banner.missing)
        return <ContentNotFound contentType="Banner" />

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/banners/">Banners</Breadcrumb.Item>
          <Breadcrumb.Item active>{!newBanner ? "Edit Banner" : "Add Banner"}</Breadcrumb.Item>
        </Breadcrumb>
        <Form title={!newBanner ? "Edit Banner" : "Add Banner"}  description="" onSubmit={saveBanner} headerAction={headerAction} >
            { !loading ?
            <>
            <Select
                value={company_id}
                label="Advertiser"
                name="company_id"
                onChange={handleChange}
                onError={handleError}
                validate="numeric"
                colSize={6}
                readOnly={!canEdit}
                formField="companies"
                useDefault={true}
            />
            <Input
                type="text"
                value={name}
                placeholder="Banner Name"
                label="Banner Name"
                name="name"
                onChange={handleChange}
                onError={handleError}
                validate="required"
                colSize="6"
                readOnly={!canEdit}
            />
            <Textarea
                type="text"
                value={code}
                placeholder="Banner Code"
                label="Banner Code"
                name="code"
                onChange={handleChange}
                onError={handleError}
                validate="required"
                colSize="6"
                readOnly={!canEdit}
            />
            <Select
                value={tracking}
                placeholder="Enable Tracking"
                label="Enable Tracking"
                name="tracking"
                onChange={handleChange}
                onError={handleError}
                validate="required"
                colSize="6"
                formField="yesno"
                readOnly={!canEdit}
            />
            {canEdit && 
            <FormFooter 
                formButtons={newBanner ? [{title: "Save Banner", class: "primary"}] :
                                    [{title: "Save Banner", class: "primary"}, 
                                        //{title: "Delete Banner", class: "danger", onClick: confirmDeleteBanner}
                                        ]
                                } 
                center={true} 
                formMessage={formMessage} 
                hasErrors={hasErrors} /> }
            </>
            : <p>Loading form data...</p> }
        </Form>
        <ConfirmDialog
            show={showConfirmDelete}
            title="Delete Banner" message="Are you sure you wish to delete this Banner?"
            onClose={() => { setShowConfirmDelete(false) }}
            onConfirm={deleteBanner}
        />
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(withRouter(BannerForm)), "Banner Form"));
