
import { connect } from 'react-redux'
import { SITE_NAME, SITE_URL } from '../../app/globals'
import { sideMenuLinks } from '../../app/sideMenuLinks'
import SideMenuLink from './SideMenuLink'

function SideMenu(props) {

    return (
        <div className="sidebar" data-color="black">
            <div className="sidebar-wrapper"><div className="logo">
                    <a href={SITE_URL} className="simple-text logo-mini"><img src="/assets/img/logos/toplineicon.png" alt="ToplineAds" border="0"  /></a>
                    <a href={SITE_URL} className="simple-text logo-normal">
                        {SITE_NAME}
                    </a>
                </div>
                <div className="user">
                    <div className="photo">
                        {props.user.iconImg?.length > 0 ? <img src={`/assets/img/faces/${props.user.iconImg}`} className="user-avatar" alt={`${props.user.name}`} /> : null }
                    </div>
                    <div className="info ">
                            <span>{props.user.name}</span>
                    </div>
                </div>
                <ul className="nav">
                    {sideMenuLinks.map((link, i) => (
                        (( (!link.reqAdmin && !link.reqSysAdmin && link.reqGroup?.length === 0)
                            || (link.reqAdmin && props.user.isAdmin) 
                            || (link.reqSysAdmin && props.user.isSysadmin) 
                            || (link.reqGroup?.length > 0 && link.reqGroup.includes(props.user.userGroup)))
                            && 
                            <SideMenuLink 
                                linkPath={link.path} 
                                linkName={link.name} 
                                icon={link.icon} 
                                active={((window.location.pathname === '/' && link.path === '/') || (link.path !== '/' && window.location.pathname.includes(link.path)))} 
                                key={`sl_${i}`} />
                        )
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default connect(state => ({...state}))(SideMenu);