export const LOGIN = "LOGIN";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_TOKEN = "SET_TOKEN";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";

export const SET_PROJECT = "SET_PROJECT";
export const DO_PROJECT_UPDATE = "DO_PROJECT_UPDATE";
export const END_PROJECT_UPDATE = "END_PROJECT_UPDATE";

export const SET_API = "SET_API";

export const SET_INVOICE = "SET_INVOICE";
export const DO_INVOICE_UPDATE = "DO_INVOICE_UPDATE";
export const END_INVOICE_UPDATE = "END_INVOICE_UPDATE";

export const SET_REPORT = "SET_REPORT";

export const SET_TOOL = "SET_TOOL";

export const SET_XML = "SET_XML";

export const LOGIN_AS_CLIENT = "LOGIN_AS_CLIENT";
export const LOGOUT_AS_CLIENT = "LOGOUT_AS_CLIENT";