import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import { FaEdit } from "react-icons/fa";
import CardHeader from '../../containers/CardHeader';
import CardSectionTitle from '../../containers/CardSectionTitle';

import toolsAPICall from '../../app/apiCalls/toolsAPICall'
import { tool_details } from "../../app/apiEndpoints"
import { Card, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ToolDetails = (props) => {
    const [tool, setTool] = useState({})
    const toolID = useSelector((state) => state.currentToolID)
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const canEdit = isSysadmin

    const headerAction = canEdit ? {'path': `/tools/${toolID}`, 'icon':FaEdit} : {}

    useMemo(() => {
        const getTool = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_details, {'toolID': toolID, 'requester': userID, 'company': companyID })
                setTool(data.tool)
            }
        }
        getTool()
    }, [toolID, userID, companyID])

    if(!toolID) return <></>

    return (
        <Card>
            <CardHeader title={tool.tool_name} description={tool.company_name} action={headerAction} />
            <Card.Body>
                <Accordion>
                    <Card>
                        <CardSectionTitle title="About" />
                        <Card.Body>
                            {tool.description}
                        </Card.Body>
                    </Card>
                    <Card>
                        <CardSectionTitle title="Admin" />
                        <Card.Body>
                            <Link to={`/tools/view/${tool.uri}/`} title="Management">Management</Link>
                        </Card.Body>
                    </Card>
                </Accordion>
            </Card.Body>
        </Card>
    )
}

export default connect(state => ({...state}))(ToolDetails)