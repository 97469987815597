import React from 'react';
import LoginHeader from "../components/Login/LoginHeader";

const loginPageContent = (WrappedComponent) => {
    class ContentWrapper extends React.Component {
        render () {
            return (
                <div className="wrapper wrapper-full-page">
                    <LoginHeader curPage={WrappedComponent.name} />
                    <div className="full-page section-image" data-color="black">
                        <div className="content">
                            <div className="container">
                                <div className="col-md-4 col-sm-6 ml-auto mr-auto">
                                    <WrappedComponent /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return ContentWrapper
}

export default loginPageContent;
