import CardHeader from '../../containers/CardHeader'
import { connect, useSelector } from 'react-redux'
import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import { Breadcrumb, Card } from 'react-bootstrap';
import MediaBuyAPITable from './MediaBuyAPITable';
import InvalidPermissions from '../../containers/InvalidPermissions';
import { FaUpload } from "react-icons/fa";

const MediaBuyDashboard = () => {
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const title = "Media Buying Dashboards";
    const subtitle = "Listing of active Media Buying Dashboards"    
    const headerAction = isSysadmin ? {'path': "/mediabuy/upload", 'icon':FaUpload} : {}

    if(!isSysadmin)
        return <InvalidPermissions />

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item active>Media Buying Dashboard</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
            <CardHeader title={title} description={subtitle} action={headerAction} />
            <MediaBuyAPITable showDetailsOnClick={false} />
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(MediaBuyDashboard), "Media Buying Dashboard"));
