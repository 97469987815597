import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';

import DataTable from '../../containers/DataTable'

import dashboardAPICall from '../../app/apiCalls/dashboardAPICall'
import { dashboard_list } from '../../app/apiEndpoints'
import { tableTypes } from '../../app/settings';

const DashboardTable = (props) => {
    const history = useHistory();
    const [data, setData] = useState([])

    const tableID = "dashboard"
    const tableFields = ["Dashboard", "Client", "# of Campaigns"]

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const rowID = useSelector((state) => state.currentClientID)

    const onClick = (e) => {
        let rowID = e.currentTarget.id
        rowID = rowID.replace(`${tableID}_`, "")
        history.push(`/dashboard/${rowID}`)

    }

    useMemo(() => {
        const getData = async () => {
            const data = await dashboardAPICall(dashboard_list, {'requester': userID, 'company': companyID })
            setData(data.dashboards)
            console.table(data.dashboards)
        }
        getData()
    }, [userID, companyID])

    const tableData = () => {
        if(data.length === 0) {
            return [];
        } 
        let rows = [];
        data.forEach(row => {
            rows.push({'id': row.dashboard_uri, 'values': [
                (props.showDetailsOnClick ? row.company_name : `${row.dashboard_name}`),
                row.company_name,
                row.campaigns
            ]});
        });
        return rows
    }
    

    return (
        <>
        <DataTable 
        hover={true} 
        tableID={tableID} 
        fieldNames={tableFields} 
        dataRows={tableData()} 
        onClick={onClick} 
        tableSettings={tableTypes.None} 
        currentRowID={rowID} />
        </>
    )
}

export default connect(state => ({...state}))(DashboardTable)