
export const topMenuLinks = [
    {
      path: "/profile/",
      name: "Profile",
      reqLogin: true
    },
    {
      path: "/admin/",
      name: "Admin",
      reqLogin: true,
      reqAdmin: true
    },
    {
      path: "/settings/",
      name: "Settings",
      reqLogin: true
    },
    {
      path: "/logout/",
      name: "Log out",
      reqLogin: true
    }
];