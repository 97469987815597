import React, { useState } from "react";
import validateInput from "../../app/functions/validateInput"

import { Row, Col, FormGroup, Card, Form } from 'react-bootstrap';

const textareaDefults = {
    height: 200,
    colSize: 9,
}

const Textarea = ({ value, label, placeholder, height, name, colSize, useColumns, validate, onChange }) => {
    const [error, setError] = useState('')

    const handleChange = (e) => {
        //pass back
        //end if no validation
        if(!validate) {
            onChange(e)
            return
        }

        onChange(e)
        setError(validateInput(label, e.target.value, validate))
    }

    if(!useColumns && !colSize)
        return (
            <FormGroup>
                {label && <Form.Label htmlFor={name}>{label}</Form.Label>}
                <textarea
                        style={{height: height ? height : textareaDefults.height}}
                        value={value}
                        name={name}
                        id={name}
                        className="form-control"
                        placeholder={placeholder}
                        onChange={handleChange}
                    />
                <Card.Text className="text-danger">{error}</Card.Text>
            </FormGroup>
        )

    return (
        <div className="form-group">
            <Row>
                {label && <Form.Label column sm="3" htmlFor={name}>{label}</Form.Label>}
                <Col md={colSize !== undefined ? colSize : textareaDefults.colSize}>
                    <textarea
                        style={{height: height ? height : textareaDefults.height}}
                        value={value}
                        name={name}
                        id={name}
                        className="form-control"
                        placeholder={placeholder}
                        onChange={handleChange}
                    />
                    <Card.Text className="text-danger">{error}</Card.Text>
                </Col>
            </Row>
        </div>
    );
};


export default Textarea;