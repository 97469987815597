import { connect, useSelector } from 'react-redux'
import { useState, useMemo, useEffect } from 'react'

import pageWithSideMenu from '../../../containers/pageWithSideMenu'
import pageContent from '../../../containers/pageContent'

import CardHeader from '../../../containers/CardHeader'
import Form from '../../../containers/Form/Form'
import Select from "../../../containers/Form/Select";
import Button from '../../../containers/Form/Button';

import Moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import toolsAPICall from '../../../app/apiCalls/toolsAPICall'
import { tool_details, tool_lp_dashboard_stats, tool_lp_dashboard_data, tool_lp_dashboard_boost } from "../../../app/apiEndpoints"
import DataTable from "../../../containers/DataTable";
import { Card, Row, Col, FormGroup, FormLabel, Breadcrumb } from 'react-bootstrap'
import { tableTypes } from '../../../app/settings'

import { CSVLink } from "react-csv";

const LanderDashboard = (props) => {
    const toolID = 10
    const [tool, setTool] = useState({})
    //const [hasErrors, setHasErrors] = useState(false)
    //const [status, setStatus] = useState(0)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const [revStats, setRevStats] = useState([])
    const [revSuccess, setRevSuccess] = useState([])
    const [flows, setFlows] = useState([])
    const [mobileFlows, setMobileFlows] = useState([])
    //const [lpStats, setLpStats] = useState([])
    const [pids, setPids] = useState([])
    const [geos, setGeos] = useState([])
    const [paths, setPaths] = useState([])

    //filters
    const [startDate, setStartDate] = useState(new Date(Moment().add(-1, 'months').format('MM/DD/YYYY')))
    const [endDate, setEndDate] = useState(new Date(Moment().format('MM/DD/YYYY')))
    const [curPid, setCurPid] = useState('')
    const [curGeo, setCurGeo] = useState('')
    const [curBoosts, setCurBoosts] = useState([])
    const [curPath, setCurPath] = useState(0)
    const [boostHours, setBoostHours] = useState(2)
    const [boostPid, setBoostPid] = useState('')
    const [formMessage, setFormMessage] = useState('')

    const tableFields = ["Path", "Leads", "Total Rev", "Rev/Lead"]
    const flowFields = ["Path", "Priority", "Percentage", "Leads", "Rev/Lead"]
    const boostFields = ["Path", "Subpid", "End Date"]
    const successFields = ["Path", "Success #", "Total #", "Success %"]

    const [revCsvStatus, setRevCsvStatus] = useState(0)
    const [revCsv, setRevCsv] = useState({})

    useMemo(() => {
        const getDetails = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_details, {'toolID': toolID, 'requester': userID, 'company': companyID })
                setTool(data.tool)
            }
        }
        getDetails()
    }, [toolID, userID, companyID])

    useEffect(() => {
        const getStats = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_lp_dashboard_stats, { 'requester': userID, 'company': companyID, 'subpid': curPid, 'geo': curGeo, 'startDate': Moment(startDate).format('YYYY-MM-DD'), 'endDate': Moment(endDate).format('YYYY-MM-DD') })
                setRevStats(data.revstats)
                setRevSuccess(data.revsuccess)
                setRevCsv({})
                setRevCsvStatus(0)
                //setLpStats(data.lpstats)
                setFlows(data.flows)
                setMobileFlows(data.flows)
                if(data.pids) setPids(data.pids)
                if(data.geos) setGeos(data.geos)
                if(data.boosts) setCurBoosts(data.boosts)
                if(data.paths) setPaths(data.paths)
            }
        }
            getStats()
    }, [userID, companyID, curPid, curGeo, startDate, endDate])

    const genReport = async (e) => {
        e.preventDefault()
        setRevCsvStatus(1)
        const csvData = await toolsAPICall(tool_lp_dashboard_data, { 'requester': userID, 'company': companyID, 'subpid': curPid, 'geo': curGeo, 'startDate': Moment(startDate).format('YYYY-MM-DD'), 'endDate': Moment(endDate).format('YYYY-MM-DD') })
        if(csvData.revcsv) { 
            setRevCsv(csvData.revcsv)
            setRevCsvStatus(2)
        }
    }

    const postBoost = async (e) => {
        e.preventDefault()
        setFormMessage("")

        if(!curPath) {
            setFormMessage("Please select a Path")
            return
        }
        if(!boostHours) {
            setFormMessage("Please enter hours to boost")
            return
        }

        const data = await toolsAPICall(tool_lp_dashboard_boost, {
            'requester': userID, 
            'company': companyID, 
            'pathid': curPath,
            'subpid': boostPid,
            'hours': boostHours
        })

        //if successful, delete from project object
        if(!data.success && data.error) {
            setFormMessage(data.error)
            return
        } 

        setFormMessage("Path boosted. Boosting will start at the top of the hour.")
        if(data.boosts) {
            setCurBoosts(data.boosts)
        }
    }

    const tableData = (type) => {
        if(revStats.length === 0) {
            return [];
        } 

        let values = revStats
        //if(type === 1) values = revStats.lastday
        //else if(type === 2) values = revStats.last7days
        //else if(type === 3) values = revStats.last30days

        let rows = [];
        values.forEach(row => {
            //if(!(curPid === '' || curPid === '0') && curPid !== row.subpid) {}
            //else {
                rows.push({'id': row.sent_to_path, 'values': [
                    row.path,
                    row.tot_leads,
                    '$' + row.tot_revenue,
                    '$' + parseFloat(row.avg_rev).toFixed(4)
                ]});
            //}
        });
        return rows
    }
    const successData = () => {
        if(revSuccess.length === 0) {
            return [];
        } 

        let values = revSuccess

        let rows = [];
        values.forEach(row => {
            //if(!(curPid === '' || curPid === '0') && curPid !== row.subpid) {}
            //else {
                rows.push({'id': row.sent_to_path, 'values': [
                    row.offer,
                    row.success,
                    row.total,
                    parseFloat(row.success_rate).toFixed(2) + '%'
                ]});
            //}
        });
        return rows
    }
    const flowData = (type) => {
        if(flows.length === 0) {
            return [];
        } 

        let values = type === 1 ? flows : mobileFlows
        let idstr = type === 1 ? "D" : "M"
        let rows = [];
        values.forEach(row => {
            //if(!(curPid === '' || curPid === '0') && curPid !== row.subpid) {}
            //else {
                rows.push({'id': `${idstr}_${row.flowid}_${row.priority}`, 'values': [
                    row.path,
                    row.priority,
                    parseFloat(row.percentage).toFixed(2) + '%',
                    row.tot_leads,
                    '$' + parseFloat(row.avg_rev).toFixed(4)
                ]});
            //}
        });
        return rows
    }
    const boostData = () => {
        if(curBoosts.length === 0) {
            return [];
        } 

        let rows = [];
        curBoosts.forEach(row => {
            //if(!(curPid === '' || curPid === '0') && curPid !== row.subpid) {}
            //else {
                rows.push({'id': `B_${row.boostid}`, 'values': [
                    row.path,
                    row.subpid,
                    row.boost_end
                ]});
            //}
        });
        return rows
    }

    /*
    const tableLPData = (type) => {
        if(lpStats.length === 0) {
            return [];
        } 

        let values = lpStats
        //if(type === 1) values = lpStats.lastday
        //else if(type === 2) values = lpStats.last7days
        //else if(type === 3) values = lpStats.last30days
        let rows = [];
        values.forEach(row => {
            //if(!(curPid === '' || curPid === '0') && curPid !== row.subpid) {}
            //else {
                rows.push({'id': row.lpid, 'values': [
                    (row.lpid === 'T' ? 'TOTAL' : 'LP #' + row.lpid),
                    row.tot_leads,
                    '$' + row.tot_revenue,
                    '$' + parseFloat(row.avg_rev).toFixed(4)
                ]});
            //}
        });
        return rows
    }
    */

    const filterPid = (e) => {
        let { value } = e.target;
        setFlows([])
        setMobileFlows([])
        setCurPid(value)
    }
    const filterGeo = (e) => {
        let { value } = e.target;
        setFlows([])
        setMobileFlows([])
        setCurGeo(value)
    }
    const setPath = (e) => {
        let { value } = e.target;
        setCurPath(value)
    }

    if(!tool) return <></>

    if(!pids) return <>No Data</>

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/tools/">Tools</Breadcrumb.Item>
          <Breadcrumb.Item active>{tool.tool_name}</Breadcrumb.Item>
        </Breadcrumb>
                <Form title={tool.tool_name} description={tool.description} >
                    <Row>
                        <Col md={6}>
                            <strong>Dates</strong>
                            <FormGroup>
                                <Row>
                                <FormLabel column sm="3" htmlFor={startDate}>Start Date</FormLabel>
                                <Col md={6}>
                                    <DatePicker id="startDate" name="startDate" selected={startDate} className="form-control" onChange={(date) => setStartDate(date)} />
                                </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                <FormLabel column sm="3" htmlFor={endDate}>End Date</FormLabel>
                                <Col md={6}>
                                <DatePicker id="endDate" name="endDate" selected={endDate} className="form-control" onChange={(date) => setEndDate(date)} />
                                </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <strong>Filters</strong>
                    {pids.length === 0 ? "" :
                    <Select
                        value={curPid}
                        label="SUBPID"
                        name="pid"
                        formValues={pids}
                        useDefault={false}
                        onChange={filterPid}
                        validate="string"
                        colSize="6"
                    />}
                    {geos.length === 0 ? "" :
                    <Select
                        value={curGeo}
                        label="GEOS"
                        name="geo"
                        formValues={geos}
                        useDefault={false}
                        onChange={filterGeo}
                        validate="string"
                        colSize="6"
                    />}
                    </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                        <CardHeader title="Revenue Per Lead - Offers" description="" />
                        <DataTable hover={false} tableID="rplo" fieldNames={tableFields} dataRows={tableData(1)} tableSettings={tableTypes.None} hideTotal={true} />
                        
                        <p align="center">
                        { revCsvStatus === 0 && // default
                            <Button title="Generate Report" className="secondary" onClick={genReport} /> }
                        { revCsvStatus === 1 && // generating has begun
                            <small>Generating Report...</small> }
                        { revCsvStatus === 2 && revCsv.file && // download now available
                        <CSVLink 
                        data={revCsv.data}
                        filename={revCsv.file}
                        className="btn btn-primary text-center"
                        target="_blank">Export Data</CSVLink>}
                        </p>
                        <CardHeader title="Offer Success Rates" description="" />
                        <DataTable hover={false} tableID="success" fieldNames={successFields} dataRows={successData()} tableSettings={tableTypes.None} hideTotal={true} />
                        </Col>
                        <Col md={6}>
                        <CardHeader title="Desktop Flow Paths" description="Desktop Flows" />
                        <DataTable hover={false} tableID="flow" fieldNames={flowFields} dataRows={flowData(1)} tableSettings={tableTypes.None} hideTotal={true} />
                        
                        <CardHeader title="Mobile Flow Paths" description="Mobile Flows" />
                        <DataTable hover={false} tableID="mflow" fieldNames={flowFields} dataRows={flowData(2)} tableSettings={tableTypes.None} hideTotal={true} />
                        
                        </Col>
                    </Row>
                </Form>
                <Form title="Path Boosting" description="Use the following tool to temporarily boost traffic for a Path. During their boost window they will receive 100% of the first cascade traffic (after AFF Coreg)." onSubmit={postBoost}>

                    <Row className='mt-4'>
                        <Col md={6}>
                        <strong>Schedule New Boost</strong><br/><br/>
                            {paths.length === 0 ? "" :
                            <Select
                                value={curPath}
                                label="PATH"
                                name="curPath"
                                formValues={paths}
                                useDefault={true}
                                onChange={setPath}
                                validate="number"
                                colSize="6"
                            />}
                            <FormGroup>
                                <Row>
                                <FormLabel column sm="3" htmlFor={boostPid}>Subpid</FormLabel>
                                <Col md={6}>
                                <input id="boostPid" name="boostPid" type='text' value={boostPid} placeholder='Subpid' className="form-control" onChange={(e) => setBoostPid(e.target.value)} />
                                </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                <FormLabel column sm="3" htmlFor={boostHours}>Hours to Boost</FormLabel>
                                <Col md={6}>
                                <input id="boostHours" name="boostHours" type='number' value={boostHours} className="form-control" onChange={(e) => setBoostHours(e.target.value)} />
                                </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                <Col md={9} className="text-center">
                                    <Card.Text>{formMessage}</Card.Text>
                                    <Button title="Boost!" className="info" />
                                </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                        <strong>Active Boosts</strong>
                        <DataTable hover={false} tableID="boosts" fieldNames={boostFields} dataRows={boostData()} tableSettings={tableTypes.None} hideTotal={true} />
                        
                        </Col>
                    </Row>
                </Form>
                
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(LanderDashboard), "Cascade Dashboard"));