
import { connect, useSelector } from 'react-redux'

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import CardHeader from '../../containers/CardHeader'

import { Card, Row, Col } from 'react-bootstrap'
import InvalidPermissions from '../../containers/InvalidPermissions'

const XMLSection = (props) => {
    const isSysadmin = useSelector((state) => state.user.isSysadmin)

    if(!isSysadmin) return <InvalidPermissions />

    return (
        <>
        <Card>
            <CardHeader title="XML Content" description="Please select the XML content you wish to manage" />
            <Card.Body>
            <Row>
                <Col md={6}>
                    <a className="btn btn-primary" href="/xml/feeds/">XML Feeds</a>
                </Col>
                <Col md={6}>
                    <a className="btn btn-primary" href="/xml/images/">XML Images</a>
                </Col>
            </Row>
            </Card.Body>
        </Card>
        </>
    )
}
export default connect(state => ({...state}))(pageWithSideMenu(pageContent(XMLSection), "XML Content"));

