
import { connect, useSelector } from 'react-redux'

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import LanderTable from './LanderTable'
import PreLanderTable from './PreLanderTable'
import CardHeader from '../../containers/CardHeader'

import { FaPlus } from "react-icons/fa";
import { Card } from 'react-bootstrap'
import InvalidPermissions from '../../containers/InvalidPermissions'

const LanderSection = (props) => {
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const headerAction = isSysadmin ? {'path': "/landers/add", 'icon':FaPlus} : {}

    if(!isSysadmin) return <InvalidPermissions />

    return (
        <>
        <Card>
            <CardHeader title="Landing Pages" description="Listing of current Landing Pages" action={headerAction} />
            <LanderTable showDetailsOnClick={true} />
        </Card>
        <Card>
            <CardHeader title="Pre-Lander Pages" description="Listing of current Pre-Lander Pages" action={headerAction} />
            <PreLanderTable showDetailsOnClick={true} />
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(LanderSection), "Landing Pages"));
