
export const API_KEY = "26b67eef276d8b67293399833adfe373";
export const API_URL = "https://api.toplineadsportal.com";

// LOGIN CALLS
export const login = { "URL": "/login/", "action": "login", "prefix": "login" };
export const login_with_token = { "URL": "/login/", "action": "load_user_from_token", "prefix": "login" };
export const logout = { "URL": "/login/", "action": "logout", "prefix": "login" };
export const forgot_password = { "URL": "/login/", "action": "forgot_password", "prefix": "login" };

// SITE CALLS
export const contact_submit = { "URL": "/site/", "action": "contact", "prefix": "site" };
export const profile_update = { "URL": "/site/", "action": "profile_update", "prefix": "site" };
export const settings_update = { "URL": "/site/", "action": "settings_update", "prefix": "site" };
export const form_fields = { "URL": "/site/", "action": "form_fields", "prefix": "site" };
export const dashboard = { "URL": "/site/", "action": "dashboard", "prefix": "site" };

// ADMIN CALLS
export const admin_stats = { "URL": "/admin/", "action": "stats", "prefix": "admin" };

// DASHBOARD CALLS
export const dashboard_list = { "URL": "/dashboard/", "action": "list", "prefix": "dashboard" };
export const dashboard_details = { "URL": "/dashboard/", "action": "details", "prefix": "dashboard" };
export const mb_dashboard_list = { "URL": "/dashboard/", "action": "mblist", "prefix": "dashboard" };
export const mb_dashboard_placements = { "URL": "/dashboard/", "action": "mbplacements", "prefix": "dashboard" };
export const mb_dashboard_offers = { "URL": "/dashboard/", "action": "mboffers", "prefix": "dashboard" };
export const data_upload = { "URL": "/dashboard/", "action": "data_upload", "prefix": "dashboard" };

// PROJECT CALLS
export const project_list = { "URL": "/projects/", "action": "list", "prefix": "projects" };
export const project_details = { "URL": "/projects/", "action": "details", "prefix": "projects" };
export const project_save = { "URL": "/projects/", "action": "save", "prefix": "projects" };
export const project_delete = { "URL": "/projects/", "action": "delete", "prefix": "projects" };
export const project_add_update = { "URL": "/projects/", "action": "add_update", "prefix": "projects" };
export const project_del_update = { "URL": "/projects/", "action": "delete_update", "prefix": "projects" };
export const project_mark_complete = { "URL": "/projects/", "action": "mark_complete", "prefix": "projects" };
export const project_form_fields = { "URL": "/projects/", "action": "form_fields", "prefix": "projects" };

// API CALLS
export const apis_list = { "URL": "/apis/", "action": "list", "prefix": "apis" };
export const api_details = { "URL": "/apis/", "action": "details", "prefix": "apis" };
export const api_save = { "URL": "/apis/", "action": "save", "prefix": "apis" };
export const api_form_fields = { "URL": "/apis/", "action": "form_fields", "prefix": "apis" };

// BANNERS CALLS
export const banner_site_list = { "URL": "/banners/", "action": "site_list", "prefix": "banners" };
export const banners_list = { "URL": "/banners/", "action": "banner_list", "prefix": "banners" };
export const banner_details = { "URL": "/banners/", "action": "details", "prefix": "banners" };
export const banner_save = { "URL": "/banners/", "action": "save", "prefix": "banners" };
export const banner_delete = { "URL": "/banners/", "action": "delete", "prefix": "banners" };
export const banner_form_fields = { "URL": "/banners/", "action": "form_fields", "prefix": "banners" };
export const banner_site_details = { "URL": "/banners/", "action": "site_details", "prefix": "banners" };
export const banner_site_save = { "URL": "/banners/", "action": "site_save", "prefix": "banners" };
export const banners_advertisers = { "URL": "/banners/", "action": "advertisers_list", "prefix": "banners" };

// LP CALLS
export const landers_list = { "URL": "/landers/", "action": "list", "prefix": "landers" };
export const prelanders_list = { "URL": "/landers/", "action": "list_pre", "prefix": "landers" };
export const lander_details = { "URL": "/landers/", "action": "details", "prefix": "landers" };
export const lander_save = { "URL": "/landers/", "action": "save", "prefix": "landers" };
export const lander_delete = { "URL": "/landers/", "action": "delete", "prefix": "landers" };
export const lander_form_fields = { "URL": "/landers/", "action": "form_fields", "prefix": "landers" };

// XML CALLS
export const xml_list = { "URL": "/xml/", "action": "list", "prefix": "xml" };
export const xml_img_list = { "URL": "/xml/", "action": "image_list", "prefix": "xml" };
export const xml_details = { "URL": "/xml/", "action": "details", "prefix": "xml" };
export const xml_details_from_uri = { "URL": "/xml/", "action": "details_from_uri", "prefix": "xml" };
export const xml_save = { "URL": "/xml/", "action": "save", "prefix": "xml" };
export const xml_image_save = { "URL": "/xml/", "action": "image_save", "prefix": "xml" };


// TOOLS CALLS
export const tools_list = { "URL": "/tools/", "action": "list", "prefix": "tools" };
export const tool_details = { "URL": "/tools/", "action": "details", "prefix": "tools" };
export const tool_details_from_uri = { "URL": "/tools/", "action": "details_from_uri", "prefix": "tools" };
export const tool_save = { "URL": "/tools/", "action": "save", "prefix": "tools" };
export const tool_form_fields = { "URL": "/tools/", "action": "form_fields", "prefix": "tools" };

export const tool_tj_pause = { "URL": "/tools/", "action": "tj_pause", "prefix": "tools" };
export const tool_tj_bid = { "URL": "/tools/", "action": "tj_bid", "prefix": "tools" };
export const tool_tj_autobid_list = { "URL": "/tools/", "action": "tj_autobid_list", "prefix": "tools" };
export const tool_tj_autobid_save = { "URL": "/tools/", "action": "tj_autobid_save", "prefix": "tools" };
export const tool_cam_models = { "URL": "/tools/", "action": "cam_models", "prefix": "tools" };
export const tool_cam_model_save = { "URL": "/tools/", "action": "cam_model_save", "prefix": "tools" };
export const tool_cam_model_hide = { "URL": "/tools/", "action": "cam_model_hide", "prefix": "tools" };
export const tool_mcl_tweet_list = { "URL": "/tools/", "action": "tool_mcl_tweet_list", "prefix": "tools" };
export const tool_mcl_tweet_save = { "URL": "/tools/", "action": "tool_mcl_tweet_save", "prefix": "tools" };
export const tool_mcl_tweet_delete = { "URL": "/tools/", "action": "tool_mcl_tweet_delete", "prefix": "tools" };
export const tool_cam_links = { "URL": "/tools/", "action": "cams_campaign_list", "prefix": "tools" };
export const tool_cam_links_link = { "URL": "/tools/", "action": "cams_campaign_link", "prefix": "tools" };
export const tool_cam_links_unlink = { "URL": "/tools/", "action": "cams_campaign_unlink", "prefix": "tools" };
export const tool_subpid_list = { "URL": "/tools/", "action": "subpid_list", "prefix": "tools" };
export const tool_subpid_details = { "URL": "/tools/", "action": "subpid_details", "prefix": "tools" };
export const tool_subpid_save = { "URL": "/tools/", "action": "subpid_save", "prefix": "tools" };
export const tool_lp_dashboard_stats = { "URL": "/tools/", "action": "lp_dashboard", "prefix": "tools" };
export const tool_lp_dashboard_data = { "URL": "/tools/", "action": "lp_dashboard_data", "prefix": "tools" };
export const tool_subpid_exc_list = { "URL": "/tools/", "action": "subpid_exc_list", "prefix": "tools" };
export const tool_subpid_exc_save = { "URL": "/tools/", "action": "subpid_exc_save", "prefix": "tools" };
export const tool_subpid_exc_delete = { "URL": "/tools/", "action": "subpid_exc_delete", "prefix": "tools" };
export const tool_subpid_cap_list = { "URL": "/tools/", "action": "subpid_cap_list", "prefix": "tools" };
export const tool_subpid_cap_save = { "URL": "/tools/", "action": "subpid_cap_save", "prefix": "tools" };
export const tool_subpid_cap_delete = { "URL": "/tools/", "action": "subpid_cap_delete", "prefix": "tools" };
export const tool_lp_dashboard_boost = { "URL": "/tools/", "action": "lp_dashboard_boost", "prefix": "tools" };
export const tool_subpid_fallback_save = { "URL": "/tools/", "action": "subpid_fallback_save", "prefix": "tools" };
export const tool_subpid_fallback_delete = { "URL": "/tools/", "action": "subpid_fallback_delete", "prefix": "tools" };
export const tool_subpid_fallback_list = { "URL": "/tools/", "action": "subpid_fallback_list", "prefix": "tools" };
export const tool_campaign_optimizer_list = { "URL": "/tools/", "action": "campaign_optimizer_list", "prefix": "tools" };
export const tool_campaign_optimizer_save = { "URL": "/tools/", "action": "campaign_optimizer_save", "prefix": "tools" };
export const tool_campaign_optimizer_logs = { "URL": "/tools/", "action": "campaign_optimizer_logs", "prefix": "tools" };


// REPORTS CALLS
export const reports_list = { "URL": "/reports/", "action": "list", "prefix": "reports" };
export const report_details = { "URL": "/reports/", "action": "details", "prefix": "reports" };
export const report_details_from_uri = { "URL": "/reports/", "action": "details_from_uri", "prefix": "reports" };
export const report_save = { "URL": "/reports/", "action": "save", "prefix": "reports" };
export const report_data = { "URL": "/reports/", "action": "data", "prefix": "reports" };
export const report_form_fields = { "URL": "/reports/", "action": "form_fields", "prefix": "reports" };

// INVOICES CALLS
export const invoices_list = { "URL": "/invoices/", "action": "list", "prefix": "invoices" };
export const invoice_details = { "URL": "/invoices/", "action": "details", "prefix": "invoices" };
export const invoice_update_status = { "URL": "/invoices/", "action": "update_status", "prefix": "invoices" };
export const invoice_save = { "URL": "/invoices/", "action": "save", "prefix": "invoices" };
export const invoice_delete = { "URL": "/invoices/", "action": "delete", "prefix": "invoices" };
export const invoice_add_project = { "URL": "/invoices/", "action": "add_project", "prefix": "invoices" };
export const invoice_rem_project = { "URL": "/invoices/", "action": "remove_project", "prefix": "invoices" };
export const invoice_form_fields = { "URL": "/invoices/", "action": "form_fields", "prefix": "invoices" };

// CLIENTS CALLS
export const clients_list = { "URL": "/clients/", "action": "list", "prefix": "clients" };
export const client_details = { "URL": "/clients/", "action": "details", "prefix": "clients" };
export const client_save = { "URL": "/clients/", "action": "save", "prefix": "clients" };
export const client_form_fields = { "URL": "/clients/", "action": "form_fields", "prefix": "clients" };

// USERS CALLS
export const users_list = { "URL": "/users/", "action": "list", "prefix": "users" };
export const user_details = { "URL": "/users/", "action": "details", "prefix": "users" };
export const user_save = { "URL": "/users/", "action": "save", "prefix": "users" };
export const user_form_fields = { "URL": "/users/", "action": "form_fields", "prefix": "users" };
