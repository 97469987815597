
import { connect, useSelector } from 'react-redux'
import { useMemo, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import bannerAPICall from '../../app/apiCalls/bannerAPICall'

import CardHeader from '../../containers/CardHeader'
import DataTable from '../../containers/DataTable'

import { banners_advertisers } from '../../app/apiEndpoints';
import { Breadcrumb, Card } from 'react-bootstrap';
import { tableTypes } from '../../app/settings';
import InvalidPermissions from '../../containers/InvalidPermissions';
import { FaPlus } from 'react-icons/fa';

const BannerAdvertiserView = (props) => {
    const history = useHistory();
    const [site, setSite] = useState({})
    const [advertisers, setAdvertisers] = useState([])
    let { siteURI } = useParams();
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)

    let title = site ? site.site_name : "Site";
    let subtitle = site ? `Advertisers for ${site.site_name}` : ""
    const headerAction = site && isSysadmin ? {'path': `/banners/${siteURI}/add`, 'icon':FaPlus} : {}

    const tableID = "banner"
    const tableFields = ["Advertiser", "# of Banners", ""]

    useMemo(() => {
        const getBanners = async () => {
            if(siteURI) {
                const data = await bannerAPICall(banners_advertisers, {'siteURI': siteURI, 'requester': userID, 'company': companyID })
                if(data) {
                    if(data.site) setSite(data.site)
                    if(data.advertisers) setAdvertisers(data.advertisers)
                }
            }
        }
        getBanners()
    }, [siteURI, userID, companyID])

    const tableData = () => {
        if(advertisers.length === 0) {
            return [];
        } 
        let rows = [];
        advertisers.forEach(row => {
            rows.push({'id': row.company_id, 'values': [
                `<a href="/banners/${siteURI}/${row.company_id}">${row.company_name}</a>`,
                row.banner_count
            ]});
        });
        return rows
    }

    const onClick = (e) => {
        let rowID = e.currentTarget.id
        rowID = rowID.replace(`banner_`, "")
        history.push(`/banners/${siteURI}/${rowID}`)
    }

    if(!isSysadmin) return <InvalidPermissions />

    if(!advertisers) return <></>

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/banners/">Banners</Breadcrumb.Item>
          <Breadcrumb.Item active>{title}</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
            <CardHeader title={title} description={subtitle} action={headerAction} />
            <DataTable hover={true} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} tableSettings={tableTypes.None} onClick={onClick}  />
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(BannerAdvertiserView), "Site Advertisers"));
