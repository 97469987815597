import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import pageWithSideMenu from '../../../containers/pageWithSideMenu'
import pageContent from '../../../containers/pageContent'

import toolsAPICall from '../../../app/apiCalls/toolsAPICall'
import { tool_campaign_optimizer_logs } from "../../../app/apiEndpoints"
import DataTable from "../../../containers/DataTable";
import CardHeader from '../../../containers/CardHeader'
import { Breadcrumb, Card, Col, Row } from 'react-bootstrap'
import { tableTypes } from "../../../app/settings";
import InvalidPermissions from '../../../containers/InvalidPermissions'

const CampaignOptimizerLog = (props) => {
    const [creativeId, setCreativeId] = useState(0)
    const [campaign, setCampaign] = useState({})
    const [creatives, setCreatives] = useState([])
    const [allLogs, setAllLogs] = useState([])
    let { campaignID } = useParams()
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [hasError, setHasError] = useState(false)

    useEffect(() => {
        const getDetails = async () => {
            if(campaignID > 0) {
                const data = await toolsAPICall(tool_campaign_optimizer_logs, 
                    {
                        'id': campaignID,
                        'requester': userID, 
                        'company': companyID
                    })
                if(data.campaign) {
                    setCampaign(data.campaign) 
                    if(data.logs) {
                        setAllLogs(data.logs)
                    }
                    if(data.creatives) {
                        setCreatives(data.creatives)
                    }
                }
                else if(data.error) {
                    setHasError(true)
                }
                setDataLoaded(true)
            }
        }
        if(!dataLoaded)
            getDetails()
    }, [campaignID, userID, companyID, dataLoaded])

    const tableFields = () => {
        if(creativeId === 0) return []
        const row = allLogs[0]
        const fields = Object.keys(row).map((field) => field)
        return fields
    }

    const tableData = () => {
        if(creativeId === 0) return []
        const dataRows = []
        allLogs.filter(log => log.creative_id === creativeId).forEach((row, i) => {
            const values = []
            Object.keys(row).forEach((field) => values.push(row[field]))
            dataRows.push({'id': i, 'values': values});
        });
        return dataRows
    }

    const displayLog = (e) => {
        setCreativeId(e.target.id)
    }
    
    if(hasError) 
        return <InvalidPermissions />

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/tools/">Tools</Breadcrumb.Item>
          <Breadcrumb.Item href="/tools/view/campaign-optimizer/">Campaign Optimizer</Breadcrumb.Item>
          <Breadcrumb.Item active>{campaign.campaign_name} Logs</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
            <CardHeader title={campaign.campaign_name} description={`Creatives for ${campaign.campaign_name}`} />
            
            <Card.Body>
            <Row>
        {!creatives || creatives.length === 0 ?
            <>
                <Col md={12}>No creatives found.</Col>
            </>
        :
        creatives.sort((a, b) => b.image_id - a.image_id).map((file, b) => (
                <Col md={3} className="text-center">
                {
                    <div key={file.creative_id}>
                    <strong>{file.creative_id}</strong><br/>
                    {file.iframe_url.length > 0 ? 
                    <iframe 
                        src={file.iframe_url} 
                        width="150" 
                        key={file.creative_id} 
                        title={file.creative_name}></iframe> : 
                    <img 
                        loading="lazy" 
                        src={file.image_url} 
                        width="150" 
                        alt={file.creative_name} 
                        id={file.creative_id} 
                        key={file.creative_id} 
                        onClick={displayLog}
                        className={(file.isactive !== "1" ? "semi-transparent" : "")+(file.creative_id === creativeId ? " hover selected_img" : " hover")} />
                    }
                    <p>{file.creative_name}</p>
                    </div>
                }
                </Col>
            ))
        }
                </Row>
                </Card.Body>
        </Card>
        <Card className="mt-4">
            <CardHeader title="Optimization Logs" />
            {creativeId !== 0 ?
            <>
            <DataTable 
                tableID={campaign.campaignID} 
                fieldNames={tableFields()} 
                dataRows={tableData()} 
                tableSettings={tableTypes.None} 
                />
            </>
            : <></>}
            {!dataLoaded ? <Card.Body>Loading logs...</Card.Body> : <></>}
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(CampaignOptimizerLog), "Campaign Optimizer Log"));
