import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom';

import DataTable from '../../containers/DataTable'

import dashboardAPICall from '../../app/apiCalls/dashboardAPICall'
import { mb_dashboard_list } from '../../app/apiEndpoints'
import { tableTypes } from '../../app/settings';

import Moment from 'moment';
import MediaBuyOptions from './MediaBuyOptions';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const MediaBuyAPITable = (props) => {
    let query = useQuery();
    const history = useHistory();
    const [data, setData] = useState([])
    const [totals, setTotals] = useState([])

    const tableID = "dashboard"
    const tableFields = ["API", "# of Placements", "Total Spend", "Total Revenue", "Total Profit", "Profit Margin"]

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const rowID = useSelector((state) => state.currentClientID)

    const startDate = query.get("startDate")
    const endDate = query.get("endDate") 

    const onClick = (e) => {
        let rowID = e.currentTarget.id
        rowID = rowID.replace(`${tableID}_`, "")
        history.push(`/mediabuy/${rowID}?startDate=${startDate || (Moment().format('MM/DD/YYYY'))}&endDate=${endDate || (Moment().format('MM/DD/YYYY'))}`)
    }

    useMemo(() => {
        const getData = async () => {
            const data = await dashboardAPICall(mb_dashboard_list, {
                'requester': userID, 
                'company': companyID, 
                'startDate': startDate || (Moment().format('MM/DD/YYYY')), 
                'endDate': endDate || (Moment().format('MM/DD/YYYY'))
             })
            if(data.apis) setData(data.apis)
            if(data.totals) setTotals(data.totals)
            console.table(data.totals)
        }
        getData()
    }, [userID, companyID, startDate, endDate])

    const tableData = () => {
        if(data.length === 0) {
            return [];
        } 
        let rows = [];
        data.forEach(row => {
            rows.push({'id': row.api_uri, 'values': [
                (props.showDetailsOnClick ? row.api_name : `<a href="/mediabuy/${row.api_uri}?startDate=${startDate || (Moment().format('MM/DD/YYYY'))}&endDate=${endDate || (Moment().format('MM/DD/YYYY'))}">${row.api_name}</a>`),
                row.placements,
                row.totalCost,
                row.totalRevenue,
                row.totalProfit,
                row.profitMargin
            ]});
        });
        return rows
    }
    

    return (
        <>
        <MediaBuyOptions
            startDate={startDate || (Moment().format('MM/DD/YYYY'))}
            endDate={endDate || (Moment().format('MM/DD/YYYY'))}
            formAction={`/mediabuy/`} />
        <DataTable 
        hover={true} 
        tableID={tableID} 
        fieldNames={tableFields} 
        dataRows={tableData()} 
        onClick={onClick} 
        tableSettings={tableTypes.None} 
        currentRowID={rowID} 
        totals={totals}
        />
        </>
    )
}

export default connect(state => ({...state}))(MediaBuyAPITable)