import { useState } from 'react'
import { connect, useSelector } from 'react-redux'

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import dashboardAPICall from '../../app/apiCalls/dashboardAPICall'
import { data_upload } from "../../app/apiEndpoints"
import CardHeader from '../../containers/CardHeader'
import { Breadcrumb, Card, Col, Row } from 'react-bootstrap'
import InvalidPermissions from '../../containers/InvalidPermissions'

import ReactFileReader from 'react-file-reader';
import Papa from 'papaparse';
import Button from "../../containers/Form/Button";

const MediaBuyAPIView = (props) => {
    const isSysadmin = useSelector((state) => state.user.isSysadmin)

    //const [api, setAPI] = useState({})
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    //const [dataLoaded, setDataLoaded] = useState(false)
    //const [hasError, setHasError] = useState(false)
    const [formMessage, setFormMessage] = useState("")
    
    const [jsonData, setJsonData] = useState([]);

    /*
    useEffect(() => {
        const getDetails = async () => {
            if(apiURI.length > 0) {
                const data = await dashboardAPICall(mb_dashboard_placements, 
                    {'apiURI': apiURI, 
                        'requester': userID, 
                        'company': companyID
                    })
                if(data.api) {
                    setAPI(data.api) 
                }
                else if(data.error) {
                    setHasError(true)
                }
                setDataLoaded(true)
            }
        }
        if(!dataLoaded)
            getDetails()
    }, [apiURI, userID, companyID, dataLoaded])
    */

    const handleFiles = files => {
        var reader = new FileReader();
        reader.onload = function(e) {
            // Use reader.result
            Papa.parse(reader.result, {
                header: true, // If your CSV has a header row
                complete: (results) => {
                setJsonData(results.data);
                },
            });
        }
        reader.readAsText(files[0]);
    }

    const saveData = async (e) => {
        e.preventDefault()
        //setHasError(false)
        
        if(!jsonData || jsonData.length === 0) { 
            //setHasError(true)
            setFormMessage("Please Upload a CSV file")
            return
        }
        setFormMessage('')

        // if(!api) {
        //     setHasError(true)
        //     setFormMessage("Unable to save to API")
        //     return 
        // }

        const data = await dashboardAPICall(data_upload, {
            //'apiID': api.api_id ?? 0, 
            'requester': userID, 
            'company': companyID, 
            'data': JSON.stringify(jsonData)
        })
        console.log(data)

        //if successful, delete from project object
        if(!data.success && data.error) {
            //setHasError(true)
            setFormMessage(data.error)
            return
        } 

        setFormMessage("Data has been saved")
    }
    
    if(!isSysadmin) 
        return <InvalidPermissions />

    //if(hasError) 
    //    return <ContentNotFound contentType="Dashboard" />

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/mediabuy/">Media Buying Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item active>Data Upload</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
            <CardHeader title="Data Upload" description="Upload data for Media Buying APIs" />

            <Row className="mt-4">
                <Col md={3} className=" m-2 ml-4 mb-4">
                    <h4 className="m-0">Select CSV to Upload</h4>
                </Col>
                <Col md={4} className=" m-2 ml-4 mb-4">
                    <ReactFileReader handleFiles={handleFiles} fileTypes={'.csv'}>
                        <button className='btn btn-primary'>Upload</button>
                    </ReactFileReader>
                </Col>
            </Row>
            {jsonData.length > 0 ? 
            <>
            <Row className="p-2">
                <Col md={12}>
                    <p>{formMessage}</p>
                    <Button onClick={saveData} title="Save Data" />
                </Col>
            </Row>
            <Row className="p-2">
                <Col md={12}>
                    <h5>CSV Data:</h5>
                    <pre>{JSON.stringify(jsonData, null, 2)}</pre>
                </Col>
            </Row>
            </>
            : <></> }
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(MediaBuyAPIView), "Media Buy API Data Upload"));
