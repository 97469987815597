import { Card, Row, Col, Table } from "react-bootstrap"
import Button from './Form/Button'
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight, FaList, FaTrash } from "react-icons/fa"
import { useState, useMemo } from "react"
import { connect, useSelector } from 'react-redux'

import { tableDefaults } from "../app/settings"

function DataTable({ tableID, dataRows, fieldNames, tableSettings, currentRowID, hover, onClick, hideTotal, totals }) {

    const settings = tableSettings ? tableSettings : tableDefaults
    const userSettings = useSelector((state) => state.user.settings.pagination)

    const pageSize = userSettings[settings.id] ? userSettings[settings.id] : settings.pageSize || tableDefaults.pageSize
    const rowCount = dataRows?.length || 0
    const pageCount = rowCount > 0 ? Math.ceil(rowCount / pageSize) : 0
    const usePagination = settings.pagination && pageCount > 0
    const [curPage, setCurPage] = useState(1)

    const isAtStart = curPage === 1
    const isAtEnd = curPage === pageCount

    useMemo(
        () => {
            if(!currentRowID) setCurPage(1)
            const rowIndex = dataRows.findIndex(row => (row.id === currentRowID))
            if(rowIndex === -1) return setCurPage(1)
            const newPage = Math.ceil(rowIndex / pageSize)
            setCurPage(newPage > 0 ? newPage : 1)
        },
        [currentRowID, pageSize, dataRows]
    )

    const dataSet = () => {
        if(pageCount === 1 || !usePagination) return dataRows
        return dataRows.slice(((curPage-1) * pageSize), (curPage * pageSize))
    }

    const goToStart = () => {
        if(!isAtStart) goToPage(1)
    }
    const goToEnd = () => {
        if(!isAtEnd) goToPage(pageCount)
    }

    const goToPrev = () => {
        if(!isAtStart) goToPage(curPage-1)
    }
    const goToNext = () => {
        if(!isAtEnd) goToPage(curPage+1)
    }

    const goToPage = (page) => {
        setCurPage(page)
    }

    return (
        <Card.Body className="table-full-width table-responsive">
            <Table responsive hover={hover ? true : false} key={tableID}>
                <thead>
                    <tr key={`${tableID}_header`}>
                    {fieldNames.map((field, i) => (
                        <th key={field}>{field}</th>
                    ))}
                    </tr>
                </thead>
                <tbody>
                    {dataSet().map((row, i) => (
                        <tr id={`${tableID}_${row.id}`} 
                            key={`${tableID}_${row.id}`} 
                            onClick={onClick} 
                            className={`${onClick ? "clickable_row" : ""} ${currentRowID && currentRowID === row.id ? "text-primary" : ""}`}>
                            {row.values.map((value, j) => (
                                <td dangerouslySetInnerHTML={{ __html: (value) }} key={`field_${j}_${i}`} />
                            ))}
                            {row.editFnc ? 
                                <td><button className="btn btn-primary btn-sm" id={`edit_${row.id}`} name={`edit_${row.id}`} onClick={row.editFnc}>Edit</button></td> : null }
                            {row.deleteFnc ? 
                                <td><button className="btn btn-secondary btn-sm" id={`delete_${row.id}`} name={`delete_${row.id}`} onClick={row.deleteFnc}><FaTrash /></button></td> : null }
                            {row.viewFnc ? 
                                <td><button className="btn btn-primary btn-sm" id={`view_${row.id}`} name={`view_${row.id}`} onClick={row.viewFnc}><FaList /></button></td> : null }
                        </tr>
                    ))}
                </tbody>
            {totals && totals.length > 0 &&
                <tfoot>
                <tr>
                {totals.map((row, i) => ( 
                    <td>{row}</td>)
                )}
                </tr>
                </tfoot>
            }
                </Table>
            {usePagination && 
                <Row>
                    <Col md={4}>
                        <Button Icon={FaAngleDoubleLeft} className={`${isAtStart ? "secondary" : "primary"} btn-sm`} disabled={isAtStart} onClick={goToStart} />
                        <Button Icon={FaAngleLeft} className={`${isAtStart ? "secondary" : "primary"} btn-sm`} disabled={isAtStart} onClick={goToPrev} />
                    </Col>
                    <Col md={4} className="text-center small pt-2">
                        Page {curPage} of {pageCount}
                        <br/>Total: {rowCount}
                    </Col>
                    <Col md={4} className="text-right">
                        <Button Icon={FaAngleRight} className={`${isAtEnd ? "secondary" : "primary"} btn-sm`} disabled={isAtEnd} onClick={goToNext} />
                        <Button Icon={FaAngleDoubleRight} className={`${isAtEnd ? "secondary" : "primary"} btn-sm`} disabled={isAtEnd} onClick={goToEnd} />
                    </Col>
                </Row>
            }
            {!usePagination && !hideTotal &&
                <Row>
                    <Col md={12} className="text-center small pt-2">
                        Total: {rowCount}
                    </Col>
                </Row>
            }
        </Card.Body>
    );
}

export default connect(state => ({...state}))(DataTable)