import { connect, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'

import pageWithSideMenu from '../../../containers/pageWithSideMenu'
import pageContent from '../../../containers/pageContent'

import CardHeader from '../../../containers/CardHeader'
import Form from '../../../containers/Form/Form'
import Button from '../../../containers/Form/Button'

import toolsAPICall from '../../../app/apiCalls/toolsAPICall'
import { tool_details, tool_subpid_cap_list, tool_subpid_cap_save, tool_subpid_cap_delete } from "../../../app/apiEndpoints"
import DataTable from "../../../containers/DataTable";
import { FaPlus } from "react-icons/fa";
import { Card, Row, Col, Breadcrumb } from 'react-bootstrap'
import { tableTypes } from '../../../app/settings'
import ConfirmDialog from '../../../containers/ConfirmDialog';

const SubpidCapTool = (props) => {
    const toolID = 12
    const [tool, setTool] = useState({})
    const [id, setId] = useState(0)
    const [pathid, setPathid] = useState(0)
    const [subpid, setSubpid] = useState('')
    const [cap, setCap] = useState(0)
    const [formMessage, setFormMessage] = useState('')
    const [updating, setUpdating] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const [caps, setCaps] = useState([])
    const [pathOptions, setPathOptions] = useState([])

    const tableID = "cap"
    const tableFields = ["Path", "Subpid", "Cap"]

    useEffect(() => {
        const getDetails = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_details, {'toolID': toolID, 'requester': userID, 'company': companyID })
                setTool(data.tool)
            }
        }
        getDetails()
    }, [toolID, userID, companyID])

    useEffect(() => {
        const getDetails = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_subpid_cap_list, { 'requester': userID })
                setCaps(data.caps)
                setPathOptions(data.paths)
            }
        }
        getDetails()
    }, [userID])


    useEffect(() => {
        const performUpdate = async () => {
            setProcessing(true)

            const data = await toolsAPICall(tool_subpid_cap_save, { 'id': id, 'subpid': subpid, 'pathid': pathid, 'cap': cap, 'requester': userID })
            if(data.error) {
                setFormMessage(data.error)
            } 
            else {
                if(data.id) {
                    setId(data.id)
                }
                if(data.caps) {
                    setCaps(data.caps)
                }
                setFormMessage("Cap saved")
            }
            setUpdating(false)
            setProcessing(false)
        }
        
        if((subpid).length > 0 && updating && !processing) {
            performUpdate()
        }
    }, [caps, id, subpid, cap, pathid, caps, updating, processing, userID])

    const tableData = () => {
        if(caps.length === 0) {
            return [];
        } 
        //console.log('data', campaigns)
        let rows = [];
        caps.forEach(row => {
            rows.push({'id': `${row.cap_id}`, 'deleteFnc': confirmDeleteCap, 'values': [
                row.path,
                row.subpid,
                row.cap
            ]});
        });
        return rows
    }

    const viewCap = (e) => {
        let rowID = e.currentTarget.id
        rowID = rowID.replace(`${tableID}_`, "")
        //look for the subpid in array
        let r = caps.find((element) => {
            return element.cap_id === rowID;
          })
        if(r === undefined) {
            return
        }
        setId(r['cap_id'])
        setSubpid(r['subpid'])
        setCap(r['cap'])
        setPathid(r['pathid'])
    }

    const addCap = (e) => {
        setId(0)
        setSubpid("")
        setCap(0)
        setPathid(0)
    }

    const updateCap = (e) => {
        e.preventDefault()
        setFormMessage("")

        if(!subpid) {
            setFormMessage("Please enter a Subpid")
            return
        }
        if(!pathid) {
            setFormMessage("Please selet a Path")
            return
        }
        if(cap.length === 0) {
            setFormMessage("Please enter a Cap value")
            return
        }

        setUpdating(true)
    }

    const confirmDeleteCap = (e) => {
        e.preventDefault()
        console.log(e)
        let rowID = e.target.id
        rowID = rowID.replace(`delete_`, "")
        if(parseInt(rowID) > 0) {
            setId(parseInt(rowID))
            setShowConfirmDelete(true)
        }
    }

    const deleteCap = async () => {
        setShowConfirmDelete(false)
        if(id <= 0) return;
        const data = await toolsAPICall(tool_subpid_cap_delete, {
            'requester': userID, 
            'company': companyID, 
            'id': id
        })

        //if successful, delete from project object
        if(!data.success && data.error) {
            setFormMessage(data.error)
            return
        } 

        if(data.caps) {
            setCaps(data.caps)
        }
        addCap()
    }

    if(!tool) return <></>
    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/tools/">Tools</Breadcrumb.Item>
          <Breadcrumb.Item active>{tool.tool_name}</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
            <Col md={12}>
                <Card>
                    <CardHeader title="Existing Caps" description="Current Caps being used on Smart Landers" action={{'function': addCap, 'icon':FaPlus}} />
                    <DataTable tableID={tableID} fieldNames={tableFields} dataRows={tableData()} tableSettings={tableTypes.None} onClick={viewCap} hover={true} />
                </Card>
            </Col>
        </Row>
            <Form title={tool.tool_name} description={tool.description} onSubmit={updateCap}> 
        <Row>
            <Col md={12}> 
                <Card.Body>
                    <Row>
                        <Col md={1} className="control-label col-form-label">Path</Col>
                        <Col md={4}>
                            <select id="pathid" className="form-control" value={pathid} readOnly={updating} onChange={(e) => setPathid(e.target.value)}>
                                <option value="0">-- Path --</option>
                                {pathOptions.map((option, o) => (
                                <option value={option.pathid} key={`p_${o}`}>{option.type}: {option.path}</option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                        <Col md={2} className="control-label col-form-label text-left pt-5"><b>Rules</b></Col>
                    <Row>
                    </Row>
                    <Row>
                        <Col md={1} className="control-label col-form-label">Subpid</Col>
                        <Col md={4}>
                            <input className="form-control" id="subpid" name="subpid" readOnly={updating} value={subpid} onChange={(e) => setSubpid(e.target.value)} placeholder='Subpid' />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={1} className="control-label col-form-label">Cap</Col>
                        <Col md={4}>
                            <input type="number" id="cap" name="cap" className="form-control" value={cap} readOnly={updating} onChange={(e) => setCap(e.target.value)} placeholder='Cap' />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="text-center pt-5">
                            <Card.Text>{formMessage}</Card.Text>
                            {!updating ? <Button title="Set Cap" className="info" /> : null}
                        </Col>
                    </Row>
                </Card.Body>
            </Col>
        </Row>
        </Form>
        <ConfirmDialog
            show={showConfirmDelete}
            title="Delete Cap" message="Are you sure you wish to delete this Cap?"
            onClose={() => { setShowConfirmDelete(false) }}
            onConfirm={deleteCap}
        />
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(SubpidCapTool), "Subpid Capping Tool"));