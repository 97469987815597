import React from "react";

import { Row, Col, FormGroup } from 'react-bootstrap';

const Button = ({ title, includeColumns, center, className, disabled, Icon, onClick }) => {
    if(!includeColumns)
        return (
            <button className={`btn btn-fill ml-3 btn-${className}`} onClick={onClick} disabled={disabled}>{Icon ? <Icon size={14} /> : title}</button>
        )

    return (
        <FormGroup>
            <Row>
                <Col md={12} className={`${center ? 'text-center' : ''}`}>
                    <button className={`btn btn-fill btn-${className}`} onClick={onClick} disabled={disabled}>{Icon ? <Icon size={14} /> : title}</button>
                </Col>
            </Row>
        </FormGroup>
    );
};


export default Button;