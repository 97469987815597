import { connect, useSelector } from 'react-redux'
import { useState, useMemo } from 'react'

import pageWithSideMenu from '../../../containers/pageWithSideMenu'
import pageContent from '../../../containers/pageContent'

import CardHeader from '../../../containers/CardHeader'
import Form from '../../../containers/Form/Form'
import Input from '../../../containers/Form/Input'
import Button from '../../../containers/Form/Button'
import ConfirmDialog from '../../../containers/ConfirmDialog';

import toolsAPICall from '../../../app/apiCalls/toolsAPICall'
import { tool_details, tool_subpid_fallback_save, tool_subpid_fallback_delete, tool_subpid_fallback_list } from "../../../app/apiEndpoints"
import DataTable from "../../../containers/DataTable";
import { Card, Row, Col, Breadcrumb } from 'react-bootstrap'
import { tableTypes } from '../../../app/settings'

const SubpidFallbackTool = (props) => {
    const toolID = 13
    const [tool, setTool] = useState({})
    const [inputValue, setInputValue] = useState({ subpid: "", fallback: ""});
    const [errors, setErrors] = useState({ subpid: "", fallback: "" });
    const { subpid, fallback } = inputValue;
    const [formMessage, setFormMessage] = useState('')
    const [hasErrors, setHasErrors] = useState(false)
    const [status, setStatus] = useState(0)
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const [subpids, setSubpids] = useState([])
    const [curID, setCurID] = useState(-1)

    const tableID = "result"
    const tableFields = ["Subpid", "Fallback URL", "Active?"]

    useMemo(() => {
        const getDetails = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_details, {'toolID': toolID, 'requester': userID, 'company': companyID })
                setTool(data.tool)
            }
        }
        getDetails()
    }, [toolID, userID, companyID])

    useMemo(() => {
        const getList = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_subpid_fallback_list, { 'requester': userID, 'company': companyID })
                setSubpids(data.subpids)
            }
        }
        getList()
    }, [userID, companyID])

    const tableData = () => {
        if(subpids.length === 0) {
            return [];
        } 

        let rows = [];
        subpids.forEach(row => {
            rows.push({'id': row.id, 'deleteFnc': confirmDeleteSubpid, 'values': [
                row.subpid,
                row.fallback_url
            ]});
        });
        return rows
    }

    const saveSubpid = async (e) => {
        e.preventDefault()
        let formErrors = []
        setHasErrors(false)
        for (const field of Object.entries(errors)) {
            const error = field[1]
            if(error.length > 0) formErrors.push(error)
        }
        if(formErrors.length > 0) { 
            setHasErrors(true)
            setFormMessage(formErrors.join('<br/>'))
            return
        }
        setFormMessage('')

        setStatus(1)

        const data = await toolsAPICall(tool_subpid_fallback_save, {
            'requester': userID, 
            'company': companyID, 
            'subpid': subpid,
            'fallback': fallback
        })

        //if successful, delete from project object
        if(!data.success && data.error) {
            setFormMessage(data.error)
            setHasErrors(true)
            setStatus(0)
            return
        } 

        if(data.tweets) {
            setFormMessage("Fallback URL Saved")
            setSubpids(data.subpids)
            setStatus(2)
        }
    }

    const confirmDeleteSubpid = (e) => {
        e.preventDefault()
        console.log(e)
        let rowID = e.target.id
        rowID = rowID.replace(`delete_`, "")
        if(parseInt(rowID) > 0) {
            setCurID(parseInt(rowID))
            setShowConfirmDelete(true)
        }
    }

    const deleteSubpid = async (e) => {
        setShowConfirmDelete(false)
        if(curID <= 0) return;
        const data = await toolsAPICall(tool_subpid_fallback_delete, {
            'requester': userID, 
            'company': companyID, 
            'id': curID
        })

        //if successful, delete from project object
        if(!data.success && data.error) {
            setFormMessage(data.error)
            setHasErrors(true)
            return
        } 

        if(data.subpids) {
            setSubpids(data.subpids)
        }
    }

    const newSubpid = () => {
        setInputValue((prev) => ({
          subpid: "", fallback: ""
        }));
        setFormMessage("")
        setStatus(0)
    }

    const handleChange = (e) => {
        console.log(e)
        let { name, value } = e.target;

        setInputValue((prev) => ({
          ...prev,
          [name]: value,
        }));
    };
    
    const handleError = (field, error) => {
        setErrors((prev) => ({
            ...prev,
            [field]: error,
        }));
    };

    if(!tool) return <></>

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/tools/">Tools</Breadcrumb.Item>
          <Breadcrumb.Item active>{tool.tool_name}</Breadcrumb.Item>
        </Breadcrumb>
            <Form title={tool.tool_name} description={tool.description} onSubmit={saveSubpid}>  
            <Card.Body>
                <Input
                    type="text"
                    value={subpid}
                    placeholder="Subpid"
                    label="Subpid"
                    name="subpid"
                    onChange={handleChange}
                    onError={handleError}
                    validate="required"
                    colSize="9"
                    readOnly={status !== 0}
                />
                <Input
                    type="text"
                    value={fallback}
                    placeholder="Fallback URL"
                    label="Fallback URL"
                    name="fallback"
                    onChange={handleChange}
                    onError={handleError}
                    validate="text|required"
                    colSize="9"
                    readOnly={status !== 0}
                />
                <Row>
                    <Col className="text-center">
                        <Card.Text className={hasErrors ? "text-danger" : "text-success"}>{formMessage}</Card.Text>
                        {status === 0 ? <Button title="Save Subpid" className="primary" /> : null}
                        {status === 2 ? <Button title="New Subpid" className="primary" onClick={newSubpid} /> : null}
                    </Col>
                </Row>
            </Card.Body>
            </Form>
            <ConfirmDialog
                show={showConfirmDelete}
                title="Delete Subpid" message="Are you sure you wish to delete this Subpid?"
                onClose={() => { setShowConfirmDelete(false) }}
                onConfirm={deleteSubpid}
            />
            <Card>
                <CardHeader title="Fallback URLs" description="Configured Subpid Fallbacks" />
                <DataTable hover={false} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} tableSettings={tableTypes.None} />
            </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(SubpidFallbackTool), "Subpid Fallback URL Config"));